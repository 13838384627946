import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/styles';
import { graphql, commitMutation } from 'react-relay';
import { useRelay, useHistory } from '../hooks';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  field: {
    marginBottom: theme.spacing(1),
  },
  button: {
    fontSize: '1em',
    fontWeight: 100,
    textTransform: 'none',
    letterSpacing: 1,
  },
  message: {
    paddingBottom: theme.spacing(1),
  },
  email: {
    color: theme.palette.primary.main,
  },
}));

function sendLoginLink(environment, email, cb) {
  commitMutation(environment, {
    mutation: graphql`
      mutation LoginEmailMutation($input: SendLoginLinkInput!) {
        sendLoginLink(input: $input) {
          clientMutationId
        }
      }
    `,
    variables: { input: { email } },
    onCompleted(resp, errors) {
      if (errors && errors[0]) {
        cb(errors[0]);
      } else {
        cb();
      }
    },
    onError: cb,
  });
}

function login(environment, idToken, cb) {
  commitMutation(environment, {
    mutation: graphql`
      mutation LoginEmailLoginMutation($input: SignInInput!) {
        signIn(input: $input) {
          user {
            id
            username
            isAdvisor
          }
        }
      }
    `,
    variables: { input: { idToken } },
    onCompleted(resp, errors) {
      if (errors && errors[0]) {
        cb(errors[0]);
      } else {
        cb(null, resp.signIn.user);
      }
    },
    onError: cb,
  });
}

function LoginEmail(props) {
  const { firebase, onSubmit, onVerify, onSuccess, ...other } = props;
  const relay = useRelay();
  const history = useHistory();
  const s = useStyles();
  const [state, setState] = useState({
    email: '',
    emailSent: false,
    loading: false,
    error: null,
  });

  useEffect(() => {
    if (!firebase) return;

    // Initialize Firebase SDK
    if (firebase.apps.length === 0) {
      firebase.initializeApp(window.config.firebase);
      firebase.auth().setPersistence(firebase.auth.Auth.Persistence.NONE);
    }

    // Try to obtain the user's email address saved into
    // session storage on the previous step.
    const email = window.localStorage.getItem('email');

    // Check whether the user came to this page via Sign-In URL.
    if (email && firebase.auth().isSignInWithEmailLink(window.location.href)) {
      verifyEmailLink(email);
    }
  }, []);

  function verifyEmailLink(email) {
    window.localStorage.removeItem('email');
    onVerify(
      new Promise(resolve => {
        firebase
          .auth()
          .signInWithEmailLink(email)
          .then(x => x.user.getIdToken())
          .then(
            idToken => {
              login(relay.environment, idToken, (err, user) => {
                if (err || !user) {
                  setState({
                    ...state,
                    loading: false,
                    error: (err && err.message) || 'Failed to sign in.',
                  });
                } else {
                  resolve(user);
                }
              });
            },
            err => {
              history.push(`/login?error=${encodeURIComponent(err.message)}`);
            },
          );
      }),
    );
  }

  function handleChange(event) {
    setState({ ...state, email: event.target.value });
  }

  function handleSubmit(event) {
    onSubmit(event);
    event.preventDefault();
    if (
      firebase &&
      firebase.auth().isSignInWithEmailLink(window.location.href)
    ) {
      verifyEmailLink(state.email);
    } else {
      window.localStorage.setItem('email', state.email);
      setState({ ...state, loading: true, error: null });
      sendLoginLink(relay.environment, state.email, err => {
        if (err) {
          setState({ ...state, loading: false, error: err.message });
        } else {
          setState({
            ...state,
            emailSent: true,
            loading: false,
            error: null,
          });
          // TODO
        }
      });
    }
  }

  return state.emailSent ? (
    <>
      <Typography className={s.message}>
        We sent an email to you at{' '}
        <span className={s.email}>{state.email}</span>. It has a magic link
        that'll sign you in.
      </Typography>
      {state.email.endsWith('@gmail.com') && (
        <Button
          className={s.button}
          variant="contained"
          color="primary"
          fullWidth
          href="https://mail.google.com"
          component="a"
        >
          Open Gmail App
        </Button>
      )}
    </>
  ) : (
    <form onSubmit={handleSubmit} {...other}>
      <TextField
        className={s.field}
        type="email"
        placeholder="Email address"
        value={state.email}
        onChange={handleChange}
        error={Boolean(state.error)}
        helperText={state.error}
        disabled={state.loading}
        fullWidth
      />
      <Button
        className={s.button}
        variant="contained"
        color="primary"
        type="submit"
        disabled={state.loading}
        fullWidth
      >
        {state.loading ? 'One moment...' : 'Continue'}
      </Button>
    </form>
  );
}

export default LoginEmail;
