/**
 * @flow
 * @relayHash 8c0fbb7543a4e2048c0f9e483f83547e
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SignInInput = {|
  idToken?: ?string,
  clientMutationId?: ?string,
|};
export type LoginEmailLoginMutationVariables = {|
  input: SignInInput
|};
export type LoginEmailLoginMutationResponse = {|
  +signIn: ?{|
    +user: ?{|
      +id: string,
      +username: string,
      +isAdvisor: ?boolean,
    |}
  |}
|};
export type LoginEmailLoginMutation = {|
  variables: LoginEmailLoginMutationVariables,
  response: LoginEmailLoginMutationResponse,
|};
*/


/*
mutation LoginEmailLoginMutation(
  $input: SignInInput!
) {
  signIn(input: $input) {
    user {
      id
      username
      isAdvisor
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "SignInInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "signIn",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SignInPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "user",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "username",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "isAdvisor",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "LoginEmailLoginMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "LoginEmailLoginMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "LoginEmailLoginMutation",
    "id": null,
    "text": "mutation LoginEmailLoginMutation(\n  $input: SignInInput!\n) {\n  signIn(input: $input) {\n    user {\n      id\n      username\n      isAdvisor\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '188022c9b267a95eea97d910d7b0638c';
module.exports = node;
