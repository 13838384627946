import clsx from 'clsx';
import React from 'react';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexBasis: '100%',
    alignItems: 'center',
    color: theme.palette.divider,
    margin: theme.spacing(1, 0),

    '&::before, &::after': {
      content: '""',
      flexGrow: 1,
      background: theme.palette.divider,
      height: 1,
      fontSize: 0,
      lineHeight: 0,
      margin: theme.spacing(0, 1),
    },
  },
}));

function HorizDivider(props) {
  const { className, children, ...other } = props;
  const s = useStyles();

  return (
    <div className={clsx(s.root, className)} {...other}>
      {children}
    </div>
  );
}

export default HorizDivider;
